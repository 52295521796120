import { all, takeEvery, put, call } from 'redux-saga/effects'
import { Edit } from 'services/tracksupdate'
import actions from './actions'


export function* EDIT({ payload,auditionid }) {
    const { adminremark } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(Edit,adminremark,auditionid)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
   return success
  }
  
}



export default function* rootSaga() {
  yield all([
    takeEvery(actions.EDIT, EDIT),
  ])
}
