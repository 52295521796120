import { notification } from 'antd'
import CommonApi from './Apiservices/CommonAPI'


export default Edit

export async function Edit(adminremark, id) {
  const state = {
    res: false
  }
  const body = { admin_remark: adminremark }

  await CommonApi.SaveData(`/api/v1/admin/audition/add/remark/${id}`, body)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state.res

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state.res
}