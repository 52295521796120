import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import instrument from './instrument/reducers'
import pricelabels from './Price_labels/reducers'
import labelpercent from './Label_Percentage/reducers'
import tracks from './labeldetail/reducers'
import category from './category/reducers'
import tracksdetail from './tracksdetail/reducers'
import menu from './menu/reducers'
import medalassign from './medalassign/reducers'
import createdisable from './createdisable/reducers'
import trackdisapprove from './trackdisapprove/reducers'
import contract from './contract/reducers'
import mail from './mail/reducers'
import faqcategory from './faq_category/reducers'
import payment from './payment/reducers'
import maillisttype from './mailinglisttype/reducers'
import auditionreason from './auditionreason/reducers'
import settings from './settings/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    instrument,
    settings,
    tracks,
    category,
    tracksdetail,
    medalassign,
    createdisable,
    pricelabels,
    labelpercent,
    trackdisapprove,
    contract,
    mail,
    faqcategory,
    payment,
    maillisttype,
    auditionreason,
  })
