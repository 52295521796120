import { all, takeEvery, put, call } from 'redux-saga/effects'
import { create , Edit } from 'services/auditionreason'
import actions from './actions'

export function* CREATE({ payload,reasontitle,reasoncontent }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success=yield call(create,payload,reasontitle,reasoncontent)
  if(success){
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
  
}

export function* EDIT({ payload , id}) {
  const {reasontitle , reasoncontent
  } = payload
yield put({
  type: 'user/SET_STATE',
  payload: {
    loading: true,
  },
})
const success=yield call(Edit,reasontitle, reasoncontent, id)
if(success){
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })

}

}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE, CREATE),
     takeEvery(actions.EDIT, EDIT),
  ])
}
