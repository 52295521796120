import axios from 'axios'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
// import reqwest from 'reqwest'

/* eslint-disable */

const Url="https://api.houseoftracks.com/"
// const Url = "http://houseoftracks.test/"
// const Url = "https://dev.houseoftracks.com/"

export default {
  url: Url,
  token: localStorage.getItem('Token'),
  getData(endpoint) {
    var Token = localStorage.getItem('Token')
    var headerData = {
      headers: {
        Authorization: `Bearer ` + Token,
      },
    }
    return axios.get(Url + endpoint, headerData).then(response => {
      return response
    })
  },
  SaveData(endpoint, body) {
    var Token = localStorage.getItem('Token')
    var headerData = {
      headers: {
        Authorization: `Bearer ` + Token,
      },
    }
    return axios.post(Url + endpoint, body, headerData).then(response => {
      return response
    })
  },
  GetDataInfo(endpoint, params = {}) {
    var Token = localStorage.getItem('Token')
    var headerData = {
      headers: {
        Authorization: `Bearer ` + Token,
      },
      params: params
    }
    return axios.get(Url + endpoint, headerData).then(response => {
      return response
    })
  },
  DisableData(endpoint) {
    var Token = localStorage.getItem('Token')
    var headerData = {
      headers: {
        Authorization: `Bearer ` + Token,
      },
    }
    return axios.delete(Url + endpoint, headerData).then(response => {
      return response
    })
  },
  RestoreData(endpoint) {
    var Token = localStorage.getItem('Token')
    var headerData = {
      headers: {
        Authorization: `Bearer ` + Token,
      },
    }
    return axios.get(Url + endpoint, headerData).then(response => {
      return response
    })
  },
  UpdateDataInfo(endpoint, body) {
    var Token = localStorage.getItem('Token')
    var headerData = {
      headers: {
        Authorization: `Bearer ` + Token,
      },
    }
    return axios.put(Url + endpoint, body, headerData).then(response => {
      return response
    })
  },
  SaveDataWithoutLogin(endpoint, body) {
    var Token = localStorage.getItem('Token')
    var headerData = {
      headers: {
        Authorization: `Bearer ` + Token,
      },
    }
    return axios.post(Url + endpoint, body).then(response => {
      return response
    })
  },
  Getdatawithoutlogin(endpoint) {
    return axios.get(Url + endpoint).then(response => {
      return response
    })
  },
  Login(endpoint, body) {
    var Token = localStorage.getItem('Token')
    var headerData = {
      headers: {
        Authorization: `Bearer ` + Token,
      },
    }
    return axios.post(Url + endpoint, body).then(response => {
      return response
    })
  },
  Register(endpoint, body) {
    var Token = localStorage.getItem('Token')
    var headerData = {
      headers: {
        Authorization: `Bearer ` + Token,
      },
    }
    return axios.post(Url + endpoint, body).then(response => {
      return response
    })
  },
  Forgot(endpoint, body) {
    var Token = localStorage.getItem('Token')
    var headerData = {
      headers: {
        Authorization: `Bearer ` + Token,
      },
    }
    return axios.post(Url + endpoint, body).then(response => {
      return response
    })
  },
  gettrackfromapi(endpoint, params) {
    var Token = localStorage.getItem('Token')
    var headerData = {
      headers: {
        Authorization: `Bearer ` + Token,
      },
      params: { ...params }
    }


    return axios.get(Url + endpoint, headerData).then(response => {
      return response
    })

    //   reqwest({
    //     url: `${CommonApi.url}`+'api/v1/admin/tracks',
    //     headers: {
    //       Authorization: `Bearer ` + CommonApi.token,
    //     },
    //     data: {
    //       label:this.state.label_filter,
    //       genre:this.state.genre_filter,
    //       tags:this.state.tags_filter,
    //       status:this.state.status_filter,
    //       ...params,
    //     },
    // }
  },
  formatDate(date, format = 'DD-MM-YYYY HH:mm') {
    return moment.tz(date, 'UTC').tz('Europe/Amsterdam').format(format)
  },
  removePs(content) {
    return content ? content.replaceAll(/\<p\>(\<hot)/ig, "$1").replaceAll(/(\<\/hot\w+\>)\<\/p\>/ig, "$1") : content
  },
  customEditorOptions() {
    return {
      addTagsWhitelist: 'hotfaq|hotcheck|hotsmiley|hotreview|hotcarousel|hotdivider|ctabutton|hottracks',
      attributesWhitelist: {
        all: 'title|start',
        'hotfaq': 'slug|category|tags|excludetags|post_count|extended',
        'hotcheck': 'title',
        'hotsmiley': 'negative',
        'hotreview': 'title|client|image',
        'ctabutton': 'link|buttontype',
        'hottracks': 'genre[]|perPage|gridView'
      },
    }
  }
}
