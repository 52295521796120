import { all } from 'redux-saga/effects'
import user from './user/sagas'
import faqcategory from './faq_category/sagas'
import instrument from './instrument/sagas'
import tracks from './labeldetail/sagas'
import category from './category/sagas'
import tracksdetail from './tracksdetail/sagas'
import medalassign from './medalassign/sagas'
import menu from './menu/sagas'
import createdisable from './createdisable/sagas'
import pricelabels from './Price_labels/sagas'
import labelpercent from './Label_Percentage/sagas'
import trackdisapprove from './trackdisapprove/sagas'
import contract from './contract/sagas'
import mail from './mail/sagas'
import payment from './payment/sagas'
import maillisttype from './mailinglisttype/sagas'
import settings from './settings/sagas'
import auditionreason from './auditionreason/sagas'

export default function* rootSaga() {
  yield all([user() ,menu(), instrument() ,labelpercent(), tracks(), category(), 
    tracksdetail(), settings(),medalassign(),createdisable(),pricelabels() , trackdisapprove() ,contract() 
    ,mail(),faqcategory(),payment(),maillisttype(),auditionreason()])
  // yield all([user(), gener() , faq() ,menu(), page(), instrument() , tracks(), category(), tracksdetail(),  , settings()])
  // yield all([user(), gener() , faq() ,menu(), page(), instrument() , tracks(), category(), tracksdetail(), medalassign() , settings()])
}
