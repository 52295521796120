import { notification } from 'antd'
import CommonApi from './Apiservices/CommonAPI'


export default create

export function create(payload) {
  const state = {
    res: false
  }

  CommonApi.SaveData('/api/v1/admin/hotpick/add', payload)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state.res

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state

}

export async function Edit(payload, id, Checkbox) {
  const state = {
    res: false
  }
  const body = {
    name: payload.trackname,
    bpm: payload.trackbpm,
    key:payload.key,
    description: payload.trackdescription,
    vocal_sample_pack: payload.vocalsamplepack,
    instrument_id: payload.instrumentid,
    vocal_category: payload.vocalcategory,
    lyrics: payload.lyrics,
    price: payload.price,
    visible_at: payload.visibleat,
    genre_id: payload.genreid,
    tags: Checkbox,
    admin_comment: payload.admin_comment,
    disapproval_text: payload.disapproval_text,
    packs: payload.packname,
    packs_url: payload.url,
    royalty_vocal_status:payload.royalty_vocal_status
  }



  await CommonApi.SaveData(`/api/v1/admin/tracks/update/info/${id}`, body)
    .then((response) => {
      notification.success({
        message: response.data.message,
      });
      state.res = true
      return state

    }).catch(error => {
      notification.warning({
        message: error.message,
      });
    })
  return state
}


